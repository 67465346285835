import React, { useState, useEffect } from "react";
import "./AdminDashboard.css";
// import { Route, Switch } from 'react-router-dom';
import { Navbar } from "../../components/Layout/Navbar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../api/auth";
// import Preloader from "../../components/Preloader/Preloader";
import Spinner from "../../components/Spinner";
import DssOptions from "../../components/DssOptions";

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { API } = useAuth();

  return (
    <div className="admin-dashboard">
      <div className="main-content">
        <Navbar />
        {/* Dashboard Overview Section */}
        <section className="dashboard-section">
          <h2>Dashboard </h2>
        </section>

        <DssOptions />

        {/* Users Management Section */}
        <section className="users-section">
          <h2>Users Management</h2>
        </section>

        {/* Add Subject Section */}
        <section className="add-subject-section">
          <h2>Add Subject</h2>
          {/* <AddSubjectPopup /> */}
        </section>

        {/* PDF Viewer Section */}
        <section className="pdf-section">
          <h2>Manage PDFs</h2>
        </section>

        {/* Profile Management Section */}
        <section className="profile-section">
          <h2>Manage Profile</h2>
          {/* <UserProfile /> */}
        </section>

        {/* Notes Management Section */}
        <section className="notes-section">
          <h2>Manage Notes</h2>
        </section>
      </div>
    </div>
  );
};

export default AdminDashboard;
