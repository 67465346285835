import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { FaSave } from "react-icons/fa";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../api/auth";

const AddReferencePopup = ({ onClose, subjectDetails, referenceToEdit, onAddReference, onUpdateReference }) => {
  const { API, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [referenceFormData, setReferenceFormData] = useState({
    refTitle: "",
    refLink: "",
    addedBy: user._id,
    addedByName: user.name,
  });

  useEffect(() => {
    if (referenceToEdit) {
      setReferenceFormData({
        refTitle: referenceToEdit.refTitle,
        refLink: referenceToEdit.refLink,
        addedBy: referenceToEdit.addedBy,
        addedByName: referenceToEdit.addedByName,
      });
    } else {
      setReferenceFormData({
        refTitle: "",
        refLink: "",
        addedBy: user._id,
        addedByName: user.name,
      });
    }
  }, [referenceToEdit, user._id, user.name]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReferenceFormData({
      ...referenceFormData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    setIsLoading(true); // Set loading state to true

    try {
        const method = referenceToEdit ? "PUT" : "POST";
        const endpoint = referenceToEdit
            ? `${API}/api/updateReference/${subjectDetails.subCode}/${referenceToEdit._id}`
            : `${API}/api/addReference/${subjectDetails.subCode}`;

        const response = await fetch(endpoint, {
            method: method,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(referenceFormData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || (referenceToEdit ? "Failed to update reference" : "Failed to add reference"));
        }

        const data = await response.json();
        toast.success(data.message);

        Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
        });

        if (referenceToEdit) {
            onUpdateReference({
                ...referenceToEdit,
                ...referenceFormData,
            });
        } else {
            onAddReference(data.newReference); 
        }
    } catch (error) {
        console.error("Error processing reference:", error);
        toast.error(referenceToEdit ? "Failed to update reference" : "Failed to add reference");
    } finally {
        setIsLoading(false); // Set loading state to false
        onClose(); // Close the popup
    }
};


  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h3>{referenceToEdit ? "Edit Reference" : "Add New Reference"}</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="inputbox">
              <input
                type="text"
                name="refTitle"
                value={referenceFormData.refTitle}
                onChange={handleInputChange}
                placeholder="Title"
                required
              />
            </div>
            <div className="inputbox">
              <input
                type="url"
                name="refLink"
                value={referenceFormData.refLink}
                onChange={handleInputChange}
                placeholder="Link"
                required
              />
            </div>
          </div>

          <div className="addReferenceBtn">
            <button type="submit" className="addReferenceBtn">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <FaSave className="save-icon" /> {referenceToEdit ? "Update Reference" : "Save Reference"}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReferencePopup;















































// import React, { useState } from "react";
// import { toast } from "react-toastify";
// import Swal from "sweetalert2";
// import { FaSave } from "react-icons/fa";
// import Spinner from "../../components/Spinner";
// import { useAuth } from "../../api/auth";

// const AddRefPopup = ({ onClose, subjectDetails }) => {
//   const { API, user } = useAuth();
//   const [isLoading, setIsLoading] = useState(false);
//   const [referenceFormData, setReferenceFormData] = useState({
//     refTitle: "",
//     refLink: "",
//     addedBy: user._id,
//     addedByName: user.name,
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setReferenceFormData({
//       ...referenceFormData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault(); // Prevent default form submission

//     setIsLoading(true); // Set loading state to true

//     try {
//       const response = await fetch(`${API}/api/addReference/${subjectDetails.subCode}`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(referenceFormData), // Use referenceFormData directly
//       });

//       if (!response.ok) {
//         throw new Error("Failed to add reference");
//       }

//       const data = await response.json();
//       toast.success(data.message);

//       Swal.fire({
//         icon: "success",
//         title: "Success",
//         text: data.message,
//       });

//     } catch (error) {
//       console.error("Error adding reference:", error);
//       toast.error("Failed to add reference");
//     } finally {
//       setIsLoading(false); // Set loading state to false
//       onClose(); // Close the popup
//     }
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <span className="close" onClick={onClose}>
//           &times;
//         </span>
//         <h3>Add New Reference</h3>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <div className="inputbox">
//               <input
//                 type="text"
//                 name="refTitle"
//                 value={referenceFormData.refTitle}
//                 onChange={handleInputChange}
//               />
//               <span>Title</span>
//             </div>
//             <div className="inputbox">
//               <input
//                 type="url"
//                 name="refLink"
//                 value={referenceFormData.refLink}
//                 onChange={handleInputChange}
//               />
//               <span>Link</span>
//             </div>
//           </div>

//           <div className="addReferenceBtn">
//             <button type="submit" className="addReferenceBtn">
//               {isLoading ? (
//                 <Spinner />
//               ) : (
//                 <>
//                   <FaSave className="save-icon" /> Save Reference
//                 </>
//               )}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddRefPopup;
