import React from 'react';

const Notes = () => {
  return (
    <div>
      <h1>Welcome to My App!</h1>
      <p>This is the Notes page of my React application.</p>
    </div>
  );
};

export { Notes };
